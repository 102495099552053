<template>
  <v-container fluid style="margin-bottom: 120px">
    <v-row class="mt-10 pl-3">
      <v-col>
        <h1 class="secondary--text">Elegibilidade</h1>
      </v-col>
    </v-row>

    <v-row class="align-center mt-10 pl-3">
      <v-col cols="12" xl="2" lg="3" md="4" sm="12" xs="12">
        <label class="label">Nome da Elegibilidade</label>
        <v-text-field
          ref="name"
          v-model="eligibility.name"
          maxlength="70"
          class="mt-2"
          placeholder="Informe o nome da Elegibilidade"
          outlined
          color="textPrimary"
          :rules="[rule.required]"
          validate-on-blur
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="4" sm="12" xs="12">
        <label class="label">Tipo de Elegibilidade</label>
        <v-autocomplete
          :items="typeEligibilities"
          v-model="eligibility.type"
          @input="validationTypeEligibilityForPlans()"
          item-text="text"
          item-value="value"
          :disabled="isDisableType"
          class="mt-2"
          placeholder="Selecione o tipo de Elegibilidade"
          outlined
          color="textPrimary"
          :rules="[rule.required]"
          validate-on-blur
        />
      </v-col>
      <v-col cols="12" xl="2" lg="3" md="4" sm="12" xs="12">
        <label class="label">Mensagem</label>
        <v-text-field
          v-model="eligibility.rule.message"
          class="mt-2"
          placeholder="Informe a mensagem"
          outlined
          color="textPrimary"
          :rules="[rule.required]"
          validate-on-blur
        />
      </v-col>
    </v-row>
    <div>
      <v-form v-model="isValidForm" lazy-validation ref="form">
        <v-row class="align-center mt-5 pl-3">
          <v-col cols="12" xl="2" lg="3" md="4">
            <label class="label">Planos</label>
            <v-autocomplete
              :items="plans"
              :loading="isLoadingPlans"
              v-model="planId"
              item-text="name"
              item-id="id"
              class="mt-2"
              placeholder="Selecione um plano"
              outlined
              return-object
              color="textPrimary"
              :rules="[rule.required]"
              validate-on-blur
            />
          </v-col>
          <v-col cols="12" xl="2" lg="3" md="4">
            <label class="label">Início de Vigência</label>
            <v-row>
              <v-col cols="12">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    class="mt-2"
                    v-model="startDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="[rule.required, rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid]"
                    validate-on-blur
                    required
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="2" lg="3" md="4">
            <label class="label">Fim de Vigência</label>
            <v-row>
              <v-col cols="12">
                <div class="d-flex flex-column">
                  <v-text-field
                    outlined
                    class="mt-2"
                    v-model="endDate"
                    append-icon="fas fa-calendar-alt"
                    placeholder="DD/MM/YYYY"
                    v-mask="'##/##/####'"
                    color="textPrimary"
                    :rules="[rule.required, rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid]"
                    validate-on-blur
                    required
                  />
                </div>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="2" lg="3" md="4" class="d-flex align-center">
            <v-btn
              class="white--text"
              x-large
              block
              color="textPrimary"
              :disabled="this.eligibility.type === 'INSURANCE_CARRIER_DEFINITION' && this.eligibility.plans.length >= 1"
              @click="addPlan()"
            >
              Adicionar Plano
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
      <v-row class="mt-10 py-0">
        <v-col cols="12">
          <v-data-table
            class="elevation-1"
            :headers="headers"
            :items="eligibility.plans"
            :items-per-page="10"
            :calculate-widths="true"
            hide-default-footer
            :loading="loadingTable"
            @item-expanded="captureIndexPlan"
            single-expand
            show-expand
          >
            <template v-slot:[`item.planId`]="{ item }">
              {{ convertNamePlan(item.planId) }}
            </template>
            <template v-slot:[`item.startDate`]="{ item }">
              {{ formatter.formatDate(item.startDate) }}
            </template>
            <template v-slot:[`item.endDate`]="{ item }">
              {{ formatter.formatDate(item.endDate) }}
            </template>
            <template v-slot:[`item.actions`]="{ item, index }">
              <v-btn class="mr-1" small icon color="textPrimary" @click="openModalEditPlan(item, index)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn x-small icon color="error" @click="openDialogDeletePlan(index)">
                <v-icon>fas fa-trash-alt</v-icon>
              </v-btn>
            </template>

            <template v-slot:expanded-item="{ item }">
              <td :colspan="headers.length" class="mx-0 px-0">
                <v-row class="pa-0 ma-0">
                  <v-col class="px-0 py-0" cols="12">
                    <v-data-table
                      loading-text="Carregando..."
                      dense
                      :headers="headersConditions"
                      :items="item.conditions"
                      item-key="id"
                      disable-sort
                      class="elevation-1 px-0"
                    >
                      <template v-slot:no-data>
                        <span>Não há regras</span>
                      </template>
                      <template v-slot:[`item.field`]="{ item }">
                        {{ convertField(item.field) }}
                      </template>
                      <template v-slot:[`item.operatorType`]="{ item }">
                        {{ convertOperator(item.operatorType) }}
                      </template>
                      <template v-slot:[`item.actions`]="{ item, index }">
                        <v-btn class="mr-1" small icon color="textPrimary" @click="openModalConditions(item, index)">
                          <v-icon>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
              </td>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>

    <v-dialog v-model="dialogConditions" persistent max-width="75%">
      <v-card>
        <div>
          <v-card-title class="pl-3 ml-3">
            <v-row>
              <v-col
                class="text-h4"
                cols="6"
                style="color: #3b495b; font-weight: bold"
                >Regras</v-col
              >
              <v-col class="d-flex justify-end align-center" cols="6">
                <v-icon
                  large
                  color="textPrimary"
                  @click="closeModalConditions()"
                >
                  fas fa-times
                </v-icon>
              </v-col>
              <v-col class="text-h5" cols="12" style="color: #3b495b">
                {{ isEditCondition ? 'Preencha os campos abaixo para editar as condiçoes de regras.' : 'Preencha os campos abaixo para adicionar condiçoes de regras.' }}
              </v-col>
            </v-row>
          </v-card-title>
          <v-row class="pa-0 ma-0" style="height: 100% !important; background-color: white">
            <v-col cols="12">
              <v-form v-model="isValidFormConditions" lazy-validation ref="formConditions">
                <v-card-text class="py-0 my-0">
                  <v-row class="align-center pl-3">
                    <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                      <label class="label">Campo</label>
                      <v-autocomplete
                        :items="fields"
                        v-model="field"
                        ref="field"
                        item-text="label"
                        item-value="value"
                        class="mt-2"
                        placeholder="Selecione um campo"
                        outlined
                        color="textPrimary"
                        @input="verifyConditionsFields()"
                        :rules="[rule.required]"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                      <label class="label">Operador</label>
                      <v-autocomplete
                        :items="operators"
                        v-model="operatorType"
                        item-text="label"
                        item-value="value"
                        class="mt-2"
                        placeholder="Selecione o operador"
                        outlined
                        color="textPrimary"
                        @input="verifyConditionsFields()"
                        :rules="[rule.required]"
                      />
                    </v-col>
                    <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                      <label class="label">Tipo de valor padrão</label>
                      <v-autocomplete
                        :items="valueTypes"
                        v-model="fieldValueType"
                        item-text="label"
                        item-value="value"
                        class="mt-2"
                        placeholder="Selecione o operador"
                        outlined
                        color="textPrimary"
                        @input="verifyConditionsFields()"
                        :rules="[rule.required]"
                      />
                    </v-col>
                    <v-col v-if="fieldValueType === 'NUMERO'" cols="12" lg="3" md="4" sm="12" xs="12">
                      <label class="label">Valor</label>
                      <v-text-field
                        v-model="fieldValue"
                        class="mt-2"
                        type="number"
                        min="1"
                        placeholder="Informe o valor"
                        outlined
                        color="textPrimary"
                        @input="verifyConditionsFields()"
                        @keydown="$event.key === '-' ? $event.preventDefault() : null"
                        @blur="verifyNegative(fieldValue)"
                        id="fieldChecked"
                        :rules="[rule.required, rule.validateNegativeDecimal(fieldValue, 'int')]"
                      />
                    </v-col>
                    <v-col v-else cols="12" lg="3" md="4" sm="12" xs="12">
                      <label class="label">Valor</label>
                      <v-text-field
                        v-model="fieldValue"
                        class="mt-2"
                        placeholder="Informe o valor"
                        outlined
                        color="textPrimary"
                        @input="verifyConditionsFields()"
                        :rules="[rule.required]"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-col>

            <v-col cols="12">
              <v-card-actions class="pb-0 mb-0 mt-15">
                <v-row class="pa-0 ma-0">
                  <v-spacer></v-spacer>
                  <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                    <v-btn
                      class="pa-6"
                      color="#3B495B"
                      outlined
                      block
                      @click="closeModalConditions()"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                    <v-btn
                      class="pa-6"
                      color="#3B495B"
                      block
                      style="color: white"
                      :disabled="isDisable"
                      @click="isEditCondition ? updateCondition() : addPlanInArray()"
                    >
                      <div>Salvar</div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogEditPlan" persistent max-width="75%">
      <v-card>
        <div>
          <v-card-title class="pl-3 ml-3">
            <v-row>
              <v-col
                class="text-h4"
                cols="6"
                style="color: #3b495b; font-weight: bold"
                >Editar Plano</v-col
              >
              <v-col class="d-flex justify-end align-center" cols="6">
                <v-icon
                  large
                  color="textPrimary"
                  @click="closeModalConditions()"
                >
                  fas fa-times
                </v-icon>
              </v-col>
            </v-row>
          </v-card-title>
          <v-row class="pa-0 ma-0" style="height: 100% !important; background-color: white">
            <v-col cols="12">
              <v-form v-model="isValidFormEditPlan" lazy-validation ref="formEditPlan">
                <v-card-text class="py-0 my-0">
                  <v-row class="align-center pl-3">
                    <v-col cols="12" xl="3" lg="4" md="4">
                      <label class="label">Planos</label>
                      <v-autocomplete
                        :items="plans"
                        :loading="isLoadingPlans"
                        v-model="planId"
                        item-text="name"
                        item-value="id"
                        class="mt-2"
                        placeholder="Selecione um plano"
                        outlined
                        color="textPrimary"
                        :rules="[rule.required]"
                      />
                    </v-col>
                      {{ planId }}
                    <v-col cols="12" xl="3" lg="4" md="4">
                      <label class="label">Início de Vigência</label>
                      <v-row>
                        <v-col cols="12">
                          <div class="d-flex flex-column">
                            <v-text-field
                              outlined
                              class="mt-2"
                              v-model="startDate"
                              append-icon="fas fa-calendar-alt"
                              placeholder="DD/MM/YYYY"
                              v-mask="'##/##/####'"
                              color="textPrimary"
                              :rules="[rule.required, rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid]"
                              required
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="12" xl="3" lg="4" md="4">
                      <label class="label">Fim de Vigência</label>
                      <v-row>
                        <v-col cols="12">
                          <div class="d-flex flex-column">
                            <v-text-field
                              outlined
                              class="mt-2"
                              v-model="endDate"
                              append-icon="fas fa-calendar-alt"
                              placeholder="DD/MM/YYYY"
                              v-mask="'##/##/####'"
                              color="textPrimary"
                              :rules="[rule.required, rule.requiredDate, rule.isAfterSpecificYear, rule.isDateValid]"
                              required
                            />
                          </div>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </v-col>

            <v-col cols="12">
              <v-card-actions class="pb-0 mb-0 mt-15">
                <v-row class="pa-0 ma-0">
                  <v-spacer></v-spacer>
                  <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                    <v-btn
                      class="pa-6"
                      color="#3B495B"
                      outlined
                      block
                      @click="closeModalEditPlan()"
                    >
                      Cancelar
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="3" md="4" sm="12" xs="12">
                    <v-btn
                      class="pa-6"
                      color="#3B495B"
                      block
                      style="color: white"
                      @click="updatePlan()"
                    >
                      <div>Salvar </div>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-col>
          </v-row>
        </div>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogDeletePlan" max-width="700px">
      <v-card class="pa-5">
        <v-card-title class="text-h5 justify-center primary--text">
          Você tem certeza que deseja excluir esse plano?
        </v-card-title>
        <v-card-actions>
        <v-row class="mt-6">
          <v-spacer></v-spacer>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              outlined
              block
              @click="closeDialogDeletePlan()"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" xs="12" sm="12" md="3" lg="3">
            <v-btn
              color="#3B495B"
              block
              style="color: white"
              @click="confirmationDeletePlan()"
            >
              Confirmar
            </v-btn>
          </v-col>
        </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col class="text-center">
        <v-snackbar
          v-model="snackbar.show"
          timeout="10000"
          top
          elevation="24"
          :color="snackbar.type"
        >
          <div class="text-center">{{ snackbar.text }}</div>
          <template v-slot:action="{ attrs }">
            <v-icon
              text
              dark
              v-bind="attrs"
              @click="snackbar.show = false"
            >
              close
            </v-icon>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>

    <Footer @voltar="onClickBack" @cancelar="onClickCancel" @salvar="onClickSave" :disabledSave="!isDisableSave" />
  </v-container>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Rules from '@/shared/validators/formRules';
import Footer from '@/components/Footer/Footer.vue';
import OperatorService from '@/services-http/sdi/OperatorService';
import FieldTypeService from '@/services-http/sdi/FieldTypeService';
import DataSourceService from '@/services-http/sdi/DataSourceService';
import EligibilityService from '@/services-http/sdi/EligibilityService';
import ContractProductService from '@/services-http/contract/ContractProductService';

export default {
  components: { Footer },
  data: () => ({
    page: 1,
    totalPages: 0,
    indexCondition: 0,
    indexPlanDelete: 0,
    isNew: false,
    isEdit: false,
    isDisable: true,
    isValidForm: true,
    loadingTable: false,
    isDisableType: false,
    isDisableSave: false,
    isLoadingPlans: false,
    dialogEditPlan: false,
    isEditCondition: false,
    dialogDeletePlan: false,
    dialogConditions: false,
    isValidFormEditPlan: true,
    isValidFormConditions: true,
    field: '',
    planId: '',
    endDate: '',
    indexPlan: '',
    startDate: '',
    fieldValue: '',
    operatorType: '',
    fieldValueType: '',
    indexEdit: 0,
    plans: [],
    fields: [],
    listAux: [],
    operators: [],
    conditions: [],
    valueTypes: [],
    typeEligibilities: [
      { text: 'Definição Corretora', value: 'INSURANCE_CARRIER_DEFINITION' },
      { text: 'Validação', value: 'VALIDATION' },
    ],
    headers: [
      {
        text: 'Plano',
        value: 'planId',
        align: 'center',
        cols: '12',
      },
      {
        text: 'Início de Vigência',
        value: 'startDate',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Fim de Vigência',
        value: 'endDate',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        cols: '6',
      },
      {
        text: '',
        value: 'data-table-expand',
      },
    ],
    headersConditions: [
      {
        text: 'Campo',
        value: 'field',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Operador',
        value: 'operatorType',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Valor',
        value: 'fieldValue',
        align: 'center',
        cols: '6',
      },
      {
        text: 'Ações',
        value: 'actions',
        align: 'center',
        cols: '6',
      },
    ],
    eligibility: {
      id: 0,
      contractId: 0,
      name: '',
      type: '',
      rule: { message: '' },
      plans: [],
    },
    snackbar: {
      show: false,
      type: '',
      text: '',
    },
  }),

  watch: {
    eligibility: {
      deep: true,
      handler(newValue) {
        if (newValue.type === 'INSURANCE_CARRIER_DEFINITION' && newValue.plans.length === 1) {
          this.isDisableSave = false;
        }
      },
    },
  },

  mounted() {
    if (this.$route.query.method === 'isEdit') {
      // eslint-disable-next-line radix
      this.eligibility.id = parseInt(this.$route.query.id);
      this.loadEligibility(this.eligibility.id);
    }
    if (this.$route.query.contractId) {
      this.eligibility.contractId = this.$route.query.contractId;
    } else {
      this.$router.push({ name: 'Home' });
    }
    this.loadFieldType();
    this.loadOperator();
    this.loadField();
    this.loadPlans();
  },

  methods: {
    loadOperator() {
      this.operatorService.FindByContext('RULES').then((response) => {
        if (response && response.data) {
          this.operators = response.data;
        }
      });
    },
    loadFieldType() {
      this.fieldTypeService.FindAll().then((response) => {
        if (response && response.data) {
          let { data } = response;
          data = data.filter((item) => item.value === 'ALFANUMERICO' || item.value === 'NUMERO');
          this.valueTypes = data;
        }
      });
    },
    loadField() {
      this.dataSourceService.FindAll().then((response) => {
        let movementFilter = [];
        if (response && response.data) {
          movementFilter = response.data.find((item) => item.value === 'registro_movimentacao').columns;
          this.fields = movementFilter;
        }
      });
    },
    loadEligibility(id) {
      this.eligibilityService.FindById(id).then((response) => {
        if (response && response.data) {
          const res = response.data;
          this.eligibility.name = res.name;
          this.eligibility.type = res.type;
          this.eligibility.rule.message = res.rule.message;
          this.eligibility.plans = res.plans;
          const eligibilityPlansAux = [];
          this.eligibility.plans.forEach((element, index) => {
            eligibilityPlansAux.push({
              ...element,
              index,
            });
            eligibilityPlansAux[index].conditions = eligibilityPlansAux[index].conditions.map((item, i) => ({
              ...item,
              index: i,
            }));
          });
          this.eligibility.plans = eligibilityPlansAux;
        }
        if (this.eligibility.plans && this.eligibility.plans.length > 1 && this.eligibility.type === 'INSURANCE_CARIER_DEFINITION') {
          this.isDisableType = true;
        } else {
          this.isDisableType = false;
        }
      });
    },
    loadPlans() {
      this.isLoadingPlans = true;
      this.contractProductService.FindAll().then((response) => {
        if (response && response.data) {
          const plans = response.data;
          plans.forEach((element) => {
            // eslint-disable-next-line no-param-reassign
            this.plans.push({
              ...element.plan_id,
              name: `${element.plan_id.name} - ${element.plan_id.code}`,
            });
          });
        }
        this.isLoadingPlans = false;
      }).catch(() => {
        this.isLoadingPlans = false;
      });
    },
    convertNamePlan(item) {
      let plan = '-';
      this.plans.forEach((element) => {
        if (item === element.id) {
          plan = element.description;
        }
      });
      return plan;
    },
    convertField(item) {
      let field = '-';
      this.fields.forEach((element) => {
        if (item === element.value) {
          field = element.label;
        }
      });
      return field;
    },
    convertOperator(item) {
      let operator = '-';
      this.operators.forEach((element) => {
        if (item === element.value) {
          operator = element.label;
        }
      });
      return operator;
    },
    verifyConditionsFields() {
      this.isDisable = !(this.field && this.operatorType && this.fieldValue && this.fieldValueType);
    },
    validationTypeEligibilityForPlans() {
      if (this.$route.query.method === 'isNew') {
        if (this.eligibility.type === 'INSURANCE_CARRIER_DEFINITION' && this.eligibility.plans.length >= 1) {
          this.eligibility.plans = [];
          this.customizeSnackbarMessage('error', 'O tipo de elegibilidade selecionado, só é permitido um plano');
        }
      } else if (this.$route.query.method === 'isEdit') {
        if (this.eligibility.type === 'INSURANCE_CARRIER_DEFINITION' && this.eligibility.plans.length > 1) {
          this.isDisableSave = true;
          this.customizeSnackbarMessage('error', 'O tipo de elegibilidade selecionado, só é permitido um plano');
        } else {
          this.isDisableSave = false;
        }
      }
    },
    captureIndexPlan(plan) {
      if (plan && plan.item.id) {
        this.indexPlan = plan.item.index;
      } else {
        // eslint-disable-next-line no-plusplus
        this.indexPlan++;
      }
    },
    openModalConditions(condition, index) {
      if (condition && condition.index) {
        this.indexCondition = condition.index;
      } else {
        // eslint-disable-next-line no-plusplus
        this.indexCondition = index;
      }
      this.dialogConditions = true;
      if (condition) {
        this.isEditCondition = true;
        this.field = condition.field;
        this.fieldValue = condition.fieldValue;
        this.fieldValueType = condition.fieldValueType;
        this.operatorType = condition.operatorType;
      }
    },
    addCondition() {
      if (this.$refs.formConditions.validate()) {
        this.conditions.push({
          field: this.field,
          operatorType: this.operatorType,
          fieldValue: this.fieldValue,
          fieldValueType: this.fieldValueType,
          indexCondition: this.conditions.length,
        });
        this.indexCondition = this.conditions.length - 1;
        this.clearCondition();
      } else {
        this.$refs.field.focus();
        this.customizeSnackbarMessage('error', 'Preencha os campos obrigatórios para prosseguir');
      }
    },
    updateCondition() {
      this.eligibility.plans[this.indexPlan].conditions[this.indexCondition].field = this.field;
      this.eligibility.plans[this.indexPlan].conditions[this.indexCondition].fieldValue = this.fieldValue;
      this.eligibility.plans[this.indexPlan].conditions[this.indexCondition].fieldValueType = this.fieldValueType;
      this.eligibility.plans[this.indexPlan].conditions[this.indexCondition].operatorType = this.operatorType;
      this.dialogConditions = false;
      this.customizeSnackbarMessage('success', 'Condições alteradas com sucesso!');
    },
    clearCondition() {
      this.field = '';
      this.operatorType = '';
      this.fieldValue = '';
      this.fieldValueType = '';
    },
    closeModalConditions() {
      this.listAux.pop();
      this.dialogConditions = false;
      this.isEditCondition = false;
      this.clearCondition();
      this.$refs.formConditions.reset();
    },
    addPlanInArray() {
      this.loadingTable = true;
      this.addCondition();
      if (this.listAux && this.listAux.length) {
        if (this.listAux.length === 1) {
          this.listAux[0].conditions = this.conditions;
        } else {
          this.listAux[this.listAux.length - 1].conditions = this.conditions;
        }
        this.listAux.forEach((element) => {
          this.eligibility.plans.push(element);
        });
        this.loadingTable = false;
      }
      this.listAux = [];
      this.closeModalConditions();
      this.$refs.formConditions.reset();
      this.$refs.form.reset();
    },
    openModalEditPlan(item, index) {
      this.indexEdit = index;
      this.planId = item.planId;
      this.startDate = this.formatter.formatDate(item.startDate);
      this.endDate = this.formatter.formatDate(item.endDate);
      this.dialogEditPlan = true;
    },
    addPlan() {
      if (this.eligibility.name && this.eligibility.type) {
        this.conditions = [];
        if (this.$refs.form.validate()) {
          this.listAux.push({
            planId: this.planId.id,
            startDate: this.formatter.formatDateBRtoString(this.startDate),
            endDate: this.formatter.formatDateBRtoString(this.endDate),
            indexPlan: this.indexPlan,
          });
          // eslint-disable-next-line no-plusplus
          this.indexPlan = this.listAux.length - 1;
          this.conditions = [];
          this.openModalConditions(null, this.indexPlan);
        }
      } else {
        this.$refs.name.focus();
        this.customizeSnackbarMessage('success', 'Informe o nome e o tipo da elegibilidade');
      }
    },
    updatePlan() {
      this.eligibility.plans[this.indexEdit].planId = this.planId;
      this.eligibility.plans[this.indexEdit].startDate = this.formatter.formatDateBRtoString(this.startDate);
      this.eligibility.plans[this.indexEdit].endDate = this.formatter.formatDateBRtoString(this.endDate);
      this.dialogEditPlan = false;
    },
    closeModalEditPlan() {
      this.dialogEditPlan = false;
    },
    openDialogDeletePlan(index) {
      this.indexPlanDelete = index;
      this.dialogDeletePlan = true;
    },
    confirmationDeletePlan() {
      this.eligibility.plans.splice(this.indexPlanDelete, 1);
      this.dialogDeletePlan = false;
    },
    closeDialogDeletePlan() {
      this.dialogDeletePlan = false;
    },
    async createEligibility() {
      if (this.eligibility.plans && this.eligibility.plans.length) {
        const plans = this.eligibility.plans.map((plan) => ({
          planId: plan.planId,
          startDate: plan.startDate,
          endDate: plan.endDate,
          conditions: plan.conditions.map((condition) => ({
            field: condition.field,
            operatorType: condition.operatorType,
            fieldValue: condition.fieldValue,
            fieldValueType: condition.fieldValueType,
          })),
        }));
        await this.eligibilityService.Save([{
          // eslint-disable-next-line radix
          contractId: parseInt(this.eligibility.contractId),
          name: this.eligibility.name,
          type: this.eligibility.type,
          rule: { message: this.eligibility.rule.message },
          plans,
        }]).then(() => {
          this.customizeSnackbarMessage('success', 'Elegibilidade cadastrada com sucesso!');
          setTimeout(() => {
            this.$router.push({ name: 'Eligibility', query: { contractId: this.eligibility.contractId } });
          }, 1000);
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao cadastrar a elegibilidade');
        });
      } else {
        this.customizeSnackbarMessage('error', 'Adicione um plano para salvar a elegibilidade');
      }
    },
    async editEligibility() {
      if (this.eligibility.plans && this.eligibility.plans.length) {
        const plans = this.eligibility.plans.map((plan) => ({
          planId: plan.planId,
          startDate: plan.startDate,
          endDate: plan.endDate,
          conditions: plan.conditions.map((condition) => ({
            field: condition.field,
            operatorType: condition.operatorType,
            fieldValue: condition.fieldValue,
            fieldValueType: condition.fieldValueType,
          })),
        }));
        await this.eligibilityService.Update([{
          // eslint-disable-next-line radix
          contractId: parseInt(this.eligibility.contractId),
          id: this.eligibility.id,
          name: this.eligibility.name,
          type: this.eligibility.type,
          rule: { message: this.eligibility.rule.message },
          plans,
        }]).then(() => {
          this.customizeSnackbarMessage('success', 'Elegibilidade editada com sucesso!');
          setTimeout(() => {
            this.$router.push({ name: 'Eligibility', query: { contractId: this.eligibility.contractId } });
          }, 1000);
        }).catch(() => {
          this.customizeSnackbarMessage('error', 'Ocorreu um erro ao cadastrar a elegibilidade');
        });
      } else {
        this.customizeSnackbarMessage('error', 'Adicione um plano para salvar a elegibilidade');
      }
    },
    onClickSave() {
      if (this.$route.query.method === 'isNew') {
        this.createEligibility();
      } else if (this.$route.query.method === 'isEdit') {
        this.editEligibility();
      }
    },
    onClickBack() {
      this.listAux = [];
      this.conditions = [];
      this.$router.push({ name: 'Eligibility', query: { contractId: this.eligibility.contractId } });
    },
    onClickCancel() {
      this.listAux = [];
      this.conditions = [];
      this.$router.push({ name: 'HomeAllMenus' });
    },
    verifyNegative(value) {
      const format = value.replace(',', '.');
      if (parseFloat(format) < 0) {
        document.getElementById('fieldChecked').focus();
      }
    },
    customizeSnackbarMessage(type, text) {
      this.snackbar.show = true;
      this.snackbar.type = type;
      this.snackbar.text = text;
    },
  },

  created() {
    this.rule = new Rules();
    this.formatter = new Formatters();
    this.operatorService = new OperatorService();
    this.fieldTypeService = new FieldTypeService();
    this.dataSourceService = new DataSourceService();
    this.eligibilityService = new EligibilityService();
    this.contractProductService = new ContractProductService();
  },
};
</script>
